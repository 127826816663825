import React, { useState, useContext } from 'react';
import { Link } from 'gatsby';
import { Formik, Form } from 'formik';
import { object } from 'yup';

import privacyPolicy from '../../../assets/documents/zasady-ochrany-osobnich-udaju.pdf';
import terms from '../../../assets/documents/podminky-sluzby.pdf';

import { AuthContext } from '../../../contexts/AuthContext';
import { ErrorContext } from '../../../contexts/ErrorContext';
import { FormGroup } from '../../shared/form/FormGroup';
import { Page } from '../../../components/shared/Page';
import { Layout } from '../../../components/shared/Layout';
import { Seo } from '../../../components/shared/Seo';
import { Button } from '../../shared/Button';
import { FormInput } from '../../shared/form/FormInput';
import { FormCheckBox } from '../../shared/form/FormCheckBox';
import { ErrorMessage } from '../../shared/form/ErrorMessage';
import { FormGroupError } from '../../shared/form/FormGroupError';
import { onPhoneChange } from '../../../utilities/onPhoneChange';
import {
  firstName,
  surname,
  email,
  phone,
  agreedWithTerms,
} from '../../../validations';
import { FormPhoneInput } from '../../shared/form/FormPhoneInput';
import { useGTMEvent } from '../../../hooks/useGTMEvent';

const ValidationSchema = object().shape({
  firstName,
  surname,
  email,
  phone,
  agreedWithTerms,
});

export const Registration = () => {
  const { register } = useContext(AuthContext);
  const { setGlobalError } = useContext(ErrorContext);
  const [error, setError] = useState(null);
  const pushGTMEvent = useGTMEvent({
    event: 'login:registration:continue',
    eventCategory: 'login',
    eventAction: 'click',
    eventLabel: 'login_registration_continue',
  });

  const initialFormValues = {
    firstName: '',
    surname: '',
    email: '',
    phone: '',
    agreedWithTerms: false,
  };

  const resetError = () => {
    setError(null);
  };

  const onSubmit = async ({ phone, ...rest }, { setSubmitting }) => {
    try {
      pushGTMEvent();
      await register({ phone: `+420${phone}`, ...rest });
    } catch (error) {
      const { isConflict, isForbidden } = error;

      if (isConflict || isForbidden) {
        setSubmitting(false);
        return setError(error);
      }

      setGlobalError(error);
    }
  };

  return (
    <Layout showLoginButton={false}>
      <Seo title="Registrace" />
      <Page
        title="Registrace"
        description={`Na Váš e-mail zašleme odkaz pro budoucí přihlašování. Telefonní číslo potřebujeme pro ověření, aby se nestalo, že by k Vašemu skóre měl přístup někdo jiný. Vaše osobní údaje jsou u nás v&nbsp;bezpečí. Více naleznete v&nbsp;dokumentu <a href=${privacyPolicy} class='underline' target="_blank" rel="noreferrer">Ochrana dat</a>.`}
      >
        <Formik
          initialValues={initialFormValues}
          validationSchema={ValidationSchema}
          onSubmit={onSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            isSubmitting,
            isValid,
            dirty,
            errors,
            touched,
            values,
          }) => (
            <Form className="flex flex-col sm:max-w-sm mx-auto">
              <FormGroup className="mt-0">
                <FormInput
                  type="text"
                  name="firstName"
                  placeholder="Jméno"
                  errors={errors}
                  touched={touched}
                  onChange={handleChange}
                  data-testid="registration-firstName"
                />
                <FormGroupError
                  name="firstName"
                  data-testid="registration-firstName-error"
                />
              </FormGroup>

              <FormGroup>
                <FormInput
                  type="text"
                  name="surname"
                  placeholder="Příjmení"
                  errors={errors}
                  touched={touched}
                  onChange={handleChange}
                  data-testid="registration-surname"
                />
                <FormGroupError
                  name="surname"
                  data-testid="registration-surname-error"
                />
              </FormGroup>

              <FormGroup>
                <FormInput
                  type="email"
                  name="email"
                  placeholder="E-mailová adresa"
                  errors={errors}
                  touched={touched}
                  onChange={(event) => {
                    resetError();
                    handleChange(event);
                  }}
                  data-testid="registration-email"
                />
                <FormGroupError
                  name="email"
                  data-testid="registration-email-error"
                />
              </FormGroup>

              <FormGroup>
                <FormPhoneInput
                  type="tel"
                  name="phone"
                  placeholder="Telefonní číslo"
                  prefix="+&nbsp;420"
                  errors={errors}
                  touched={touched}
                  onChange={(event) => {
                    resetError();
                    onPhoneChange(event, handleChange, setFieldValue);
                  }}
                  data-testid="registration-phone"
                />
                <FormGroupError
                  name="phone"
                  data-testid="registration-phone-error"
                />
              </FormGroup>

              <FormGroup className="mt-4 sm:mt-6 mx-auto">
                <FormCheckBox
                  name="agreedWithTerms"
                  errors={errors}
                  touched={touched}
                  value={values.agreedWithTerms}
                  onChange={handleChange}
                  data-testid="registration-agreed-with-terms"
                >
                  Souhlasím s&nbsp;
                  <a
                    className="underline cursor-pointer"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={terms}
                  >
                    podmínkami služby Osobní skóre
                  </a>
                </FormCheckBox>
                <FormGroupError name="agreedWithTerms" />
              </FormGroup>

              {error?.isConflict && (
                <ErrorMessage data-testid="registration-conflict-error">
                  Vyplněný e-mail nebo telefon už známe.
                  <Link
                    to="/ucet/prihlaseni/"
                    className="ml-1 underline"
                    data-testid="registration-error-link-to-login"
                  >
                    Přihlašte se
                  </Link>
                  , prosím.
                </ErrorMessage>
              )}

              {error?.isForbidden && (
                <ErrorMessage data-testid="registration-conflict-error">
                  Službu zatím testujeme jen s pár uživateli. Velmi brzy bude
                  přístupná i pro Vás.
                </ErrorMessage>
              )}

              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting || !dirty || !isValid}
                className="mt-6"
                data-testid="registration-submit"
              >
                Pokračovat
              </Button>
              <p className="mt-6 text-center text-sm">
                Už máte účet?
                <Link to="/ucet/prihlaseni/" className="ml-1 underline">
                  Přihlašte se
                </Link>
              </p>
            </Form>
          )}
        </Formik>
      </Page>
    </Layout>
  );
};
