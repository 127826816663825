import React from 'react';
import PropTypes from 'prop-types';
import { FormInput, formInputProps } from '../FormInput';

export const FormPhoneInput = ({
  errors,
  name,
  touched,
  prefixTest,
  prefix,
  className = '',
  ...rest
}) => {
  const hasError = errors[name] && touched[name];
  const containerCss = `flex bg-white border border-solid border-grey-2 rounded-full ${
    hasError ? 'border-red text-red' : ''
  }`;
  const inputCss = `py-4 pr-8 pl-2 rounded-r-full ${className}`;
  const prefixCss = `flex items-center pl-8 select-none ${
    hasError ? 'color-red' : 'text-grey-2'
  }`;

  return (
    <div className={containerCss} data-testid="form-group-field-container">
      {prefix && (
        <span className={prefixCss} {...prefixTest}>
          {prefix}
        </span>
      )}
      <FormInput
        errors={errors}
        touched={touched}
        name={name}
        className={inputCss}
        unstyled
        {...rest}
      />
    </div>
  );
};

FormPhoneInput.propTypes = {
  ...formInputProps,
  type: PropTypes.string.isRequired,
  prefixTest: PropTypes.object,
  prefix: PropTypes.string.isRequired,
};
